import axios from "axios";

    export const getCSRFToken = async () => {
        axios.defaults.xsrfHeaderName = "X-CSRFToken";
        axios.defaults.xsrfCookieName = "csrftoken";
        axios.defaults.withCredentials = true;
        return axios.get(API_URL + '/csrf/',{
            xsrfHeaderName: "X-CSRFToken",
            withCredentials: true,
            withXSRFToken: true
        }).then(response => {
            axios.defaults.headers.post['X-CSRFToken'] = response.data.csrf_token
            return response.data;
        }).catch(error => {
            return error;
        });
    }


    export const getSession = async () => {
        return axios.get(API_URL + '/session/',{
            xsrfHeaderName: "X-CSRFToken",
            withCredentials: true,
            withXSRFToken: true
        }).then(response => {
            return response.data;
        }).catch(error => {
            return error;
        });
    };


    export const loginSession = async (data) => {
        return axios.post(API_URL + '/login/', data, {
            xsrfHeaderName: "X-CSRFToken",
            withCredentials: true,
            withXSRFToken: true
        })
        .then(async (response) => {
            return response;
        })
        .catch((error) => {
            return error
        });
    }


    export const logoutSession = async () => {
        return axios.get(API_URL + '/logout/', {
            xsrfHeaderName: "X-CSRFToken",
            withCredentials: true,
            withXSRFToken: true
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        })
    }


    export const whoAmI = async (data) => {
        return axios.get(API_URL + '/whoami/',{
            xsrfHeaderName: "X-CSRFToken",
            withCredentials: true,
            withXSRFToken: true
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        })
    }
