import React from 'react'
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom'
import {getSession} from './components/csrf/csrfManager'
import './scss/style.scss'
import Cookies from "js-cookie";
import {MathJaxContext} from "better-react-mathjax";
import {Suspense, useCallback, useEffect, useRef, useState} from "react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))


const App = () => {
    const [isAuth, setAuth] = useState(false)
    const initialized = useRef(false)
    const setAuthorization = useCallback(boolean => {
        setAuth(boolean);
    },[setAuth]);

    useEffect(()=> {
        const checkSession = async() => {
            if (!initialized.current) {
                if (Cookies.get('csrftoken') !== undefined) {
                    let isSession = await getSession();
                    setAuth(isSession.isAuthenticated);
                }
            }
        }
        checkSession().then();
        initialized.current = true;
    },[])

    return (
      <MathJaxContext>
          <HashRouter>
            <Suspense fallback={loading}>
              <Routes>
                <Route exact path="/login" name="Login Page" element={isAuth ? <Navigate to="/*"/> : <Login appSetAuth={setAuthorization}/>} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />
                <Route path="/*" name="Home" element={isAuth ? <DefaultLayout /> : <Navigate to="/login"/>} />
              </Routes>
            </Suspense>
          </HashRouter>
      </MathJaxContext>
    )
}

export default App
